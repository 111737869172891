import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import sr from '@utils/sr';
import { srConfig } from '@config';
import styled from 'styled-components';
import { theme, mixins, media, Heading, Section, Button } from '@styles';
import skillChart from '@images/skills-chart-hbar.svg';

const { colors, fontSizes } = theme;

const StyledContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
`;
const StyledSkillHeader = styled.div`
  ${mixins.flexCenterHorizontal};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  padding-top: 20px;
  font-size: ${fontSizes.xxxl};
  ${media.phablet`
    font-size: ${fontSizes.lg};
  `}
  font-weight: 700;

  > div {
    display: flex;
    flex: 1;
    flex-direction: row;

    > div {
      display: flex;
      flex: 1;
      justify-content: center;
      border-left: 1px solid ${colors.lightSlate};
    }

    &:first-child {
      border-left: none;
      width: 25%;
      max-width: 25%;
      padding: 0 0.25rem;
    }
  }
`;
const StyledGrid = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;

  .skills {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;
const StyledSkill = styled.div`
  transition: ${theme.transition};
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  flex-shrink: 0;
  outline: 0;

  .skillName {
    width: 25%;
    max-width: 25%;
    padding: 0 0.25rem;
    text-align: center;
    word-break: break-word;

    ${media.phablet`
      font-size: ${fontSizes.sm};
    `}
  }

  .skillLevel {
    flex: 1;
  }
`;
const StyledGraphImage = styled.div`
  background: url(${skillChart}) no-repeat 100% 50%;
  height: 40px;
  transition: width 0.5s;
`;
const StyledMoreButton = styled(Button)`
  margin: 100px auto 0;
`;

const Skills = ({ data }) => {
  const [showMore, setShowMore] = useState(false);
  const revealContainer = useRef(null);
  const revealTitle = useRef(null);
  const revealArchiveLink = useRef(null);
  const revealSkills = useRef([]);

  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealArchiveLink.current, srConfig());
    revealSkills.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const GRID_LIMIT = 10;
  const HEIGHT_PER_SKILL = 60;
  const HEADER_HEIGHT = 60;

  const skills = data
    .filter(({ node }) => node)
    .map(({ node: { frontmatter } }) => frontmatter.skills)
    .reduce((acc, cur) => acc.concat(cur), [])
    .sort((a, b) => {
      const level1 = a.split('|')[1].trim();
      const level2 = b.split('|')[1].trim();
      return parseFloat(level2) - parseFloat(level1);
    });
  const firstSix = skills.slice(0, GRID_LIMIT);
  const skillsToShow = showMore ? skills : firstSix;

  return (
    <StyledContainer id="skills" ref={revealContainer}>
      <Heading>My Technical Expertise</Heading>

      <StyledGrid>
        <StyledSkillHeader>
          <div></div>
          <div>
            <div>Beginner</div>
            <div>Proficient</div>
            <div>Expert</div>
          </div>
        </StyledSkillHeader>
        <TransitionGroup
          className="skills"
          style={{
            height: `${HEADER_HEIGHT + skillsToShow.length * HEIGHT_PER_SKILL}px`,
          }}>
          {skillsToShow &&
            skillsToShow.map((skill, i) => {
              const val = skill.split('|').map(v => v.trim());
              const skillName = val[0];
              const skillLevel = val[1];
              return (
                <CSSTransition
                  key={skillName}
                  classNames="fadeup"
                  timeout={i >= GRID_LIMIT ? (i - GRID_LIMIT) * 300 : 300}
                  exit={false}>
                  <StyledSkill
                    key={skillName}
                    ref={el => (revealSkills.current[i] = el)}
                    tabIndex="0"
                    style={{
                      transitionDelay: `${i >= GRID_LIMIT ? (i - GRID_LIMIT) * 100 : 0}ms`,
                      height: `${HEIGHT_PER_SKILL}px`,
                      marginTop: `${i === 0 ? HEADER_HEIGHT : 0}px`,
                    }}>
                    <div className="skillName">{skillName}</div>
                    <div className="skillLevel">
                      <StyledGraphImage style={{ width: `${skillLevel * 10}%` }} />
                    </div>
                  </StyledSkill>
                </CSSTransition>
              );
            })}
        </TransitionGroup>
      </StyledGrid>

      <StyledMoreButton onClick={() => setShowMore(!showMore)}>
        Show {showMore ? 'Less' : 'More'}
      </StyledMoreButton>
    </StyledContainer>
  );
};

Skills.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Skills;

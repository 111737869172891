import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(0, 0)">
        <path
          d="M61.94,65.36a.94.94,0,0,1-.14.5,1,1,0,0,1-.53.35,5.16,5.16,0,0,1-1.07.21,16.15,16.15,0,0,1-1.72.08,14.62,14.62,0,0,1-2.37-.13,2.24,2.24,0,0,1-1-.38,1.67,1.67,0,0,1-.49-.6L44.71,49.87V65.39a.75.75,0,0,1-.16.48,1.1,1.1,0,0,1-.53.34,4.76,4.76,0,0,1-1,.21,14,14,0,0,1-1.61.08,14.23,14.23,0,0,1-1.61-.08,4.73,4.73,0,0,1-1-.21,1.2,1.2,0,0,1-.54-.34.76.76,0,0,1-.17-.48V34.59a.72.72,0,0,1,.17-.48,1,1,0,0,1,.54-.33,4.82,4.82,0,0,1,1-.2,14.23,14.23,0,0,1,1.61-.08,14,14,0,0,1,1.61.08,4.84,4.84,0,0,1,1,.2,1,1,0,0,1,.53.33.71.71,0,0,1,.16.48V48.51l9.57-13.9a1.8,1.8,0,0,1,.43-.54,1.86,1.86,0,0,1,.66-.34,4.27,4.27,0,0,1,1-.18c.42,0,1,0,1.61,0a15,15,0,0,1,1.67.08,5.07,5.07,0,0,1,1.05.21,1.14,1.14,0,0,1,.54.34.77.77,0,0,1,.15.46,2,2,0,0,1-.22.88c-.16.3-.44.78-.86,1.44l-9,11.75,9.78,14.78a12.18,12.18,0,0,1,.67,1.38A2,2,0,0,1,61.94,65.36Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;

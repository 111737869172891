module.exports = {
  siteTitle: 'Khubaib Qaiser | Software Engineer',
  siteDescription:
    'Khubaib Qaiser is a software engineer based in Islamabad, Pakistan who specializes in building exceptional websites, applications, and everything in between.',
  siteKeywords:
    'Khubaib Qaiser, Khubaib, Qaiser, khubaibqaiser, software engineer, frontend, senior software engineer, SE, developer, front-end engineer, web developer, javascript, es6, es7, typescript, react, react native, next, nextjs, js, ts, RN, CMS, contentful, strapi, headless cms, vercel, netlify, aws, amplify, github, ci/cd, CI & CD, continuous integration, continuous deployment, reactnative, pakistan, remote, react, reactjs, next, nextjs, typescript, ts, js, javascript, es6, vercel, aws, amplify, netlify, google, firebase, firestore, google maps, api, rest, axios, graphql, webhooks, web3, contentful, strapi, headless cms, cms, git, github, slack, npm, library, components, hooks, formik, yup',
  siteUrl: 'https://khubaibqaiser.com',
  siteLanguage: 'en_US',
  googleAnalyticsID: '',
  googleVerification: '',
  name: 'Khubaib Qaiser',
  location: 'Islamabad, Pakistan',
  email: 'khubaib.dev@gmail.com',
  github: 'https://github.com/khubaibqaiser',
  twitterHandle: '@khubaibqaiser',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/khubaibqaiser',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/khubaib-qaiser',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/khubaibqaiser',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/khubaibqaiser',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Skills',
      url: '/#skills',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#3d2c29',
    darkNavy: '#fafaf9',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
